import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

/** API User */
export function getAllUser(listQuery) {
  // console.log('on api getUser')
  // console.log(listQuery)

  var page = listQuery.page
  var limit = listQuery.limit
  var search = listQuery.search

  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/cusqueries/getallUser?search=' + search + '&_limit=' + limit + '&_start=' + start
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countUser(listQuery) {
  var search = listQuery.search

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/cusqueries/countuser?search=' + search
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getUserBdays() {
  // console.log('on api getUserBdays')

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/cusqueries/bdays'
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getActiveUser() {
  // console.log('on api getActiveUser')

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/users?confirmed=1&blocked_ne=1&fullname_ncontains=admin&fullname_ncontains=adm&_limit=2000'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}
/*
export function getActiveUser() {
  // console.log('on api getActiveUser')

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/cusqueries/getuser'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}
*/

export function getUserByName(query) {
  // console.log('on api getUserByName')

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/users?fullname_contains=' + query + '&confirmed=true&blocked=false&_sort=fullname%3AASC&_limit=2000'
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getUserByID(id) {
  // console.log('on api getUserByID')
  // console.log(id)
  if (id <= 0) return null

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var uri = _GLOBAL.URLBACKEND + '/users/' + id
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function insertUser(data) {
  // console.log('insertUser')
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null

  var uri = _GLOBAL.URLBACKEND + '/users'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function deleteUser(id) {
  // console.log('deleteUser')
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/users/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function updateUser(data, id) {
  // console.log('updateUser')

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/users/' + id
  var response = request({
    url: uri,
    method: 'put',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function uploadAvatarUser(refID, file) {
  // console.log('uploadAvatarUser')
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  const bodyFormData = new FormData()
  bodyFormData.append('refId', refID)
  bodyFormData.append('field', 'avatar')
  bodyFormData.append('ref', 'user')
  bodyFormData.append('source', 'users-permissions')
  bodyFormData.append('path', 'user/avatar')
  // Add each file upload to the form data
  bodyFormData.append('files', file, file.name)
  // console.log(file.name)
  
  var uri = _GLOBAL.URLBACKEND + '/upload'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Authorization': `Bearer ${token}`
    },
    data: bodyFormData,
    contentType: false,
    processData: false,
    crossDomain: true,
    async: true,
    mimeType: 'multipart/form-data'

  })
  // console.log(response)
  return response
}

/** API User ==== */

/** API Role */
export function getListRole() {
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/users-permissions/roles'
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getDashboardInfo() {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/dashboardinfos/m/info'
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}


export function getRSSWebsite() {

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  
  var uri = _GLOBAL.URLBACKEND + '/cusqueries/getWeb'
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getStsSPD_Idempiere(myname, listQuery) {

  var page = listQuery.page
  var limit = listQuery.limit
  var search = listQuery.search
  var thang = listQuery.thang
  // console.log(limit)

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'

  var start = (page - 1) * limit
  if (start < 0) start = 0

  var uri = _GLOBAL.URLBACKEND + '/cusqueries/getsts_spd/f?name=' + myname + '&search='+search + '&_limit=' + limit + '&_start=' + start + '&thang=' + thang+ '&_sort=id%3AASC'
  var data = listQuery
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })

  // console.log(response)
  return response
}

export function countStsSPD_Idempiere(myname, listQuery) {
  var search = listQuery.search
  var thang = listQuery.thang

  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/cusqueries/getsts_spd/c?name=' + myname + '&search='+search + '&thang=' + thang
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function sendNotifSMS(mdn, message, idapprval) {
  // console.log('sendNotifSMS')
  var token = secureStorage.getItem('jwt')
  if (token == null) throw 'token null'
  if (!mdn || !message || mdn == '' || message == '') return null

  var data = {
    nohp: mdn,
    msg: message,
    idapproval: idapprval
  }
  
  var uri = _GLOBAL.URLBACKEND + '/cusqueries/hub/sendsms'
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

/** API Role ==== */
